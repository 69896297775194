import { z } from 'astro:schema';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function onClickOutside(
  element: HTMLElement,
  handler: (event: MouseEvent) => void,
) {
  function listener(event: MouseEvent) {
    if (!element.contains(event.target as Node)) {
      handler(event);
    }
  }

  document.addEventListener('click', listener);
  return {
    destroy() {
      document.removeEventListener('click', listener);
    },
  };
}

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

export function formatCurrency(
  amount: number,
  currency = 'USD',
  minimumFractionDigits = 0,
) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits,
  }).format(amount / 100);
}

export function groupBy<T extends Record<string, unknown>>(
  values: T[],
  keyFinder: string | ((value: T) => string),
) {
  // using reduce to aggregate values
  return values.reduce((a: Record<string, T[]>, b) => {
    // depending upon the type of keyFinder
    // if it is function, pass the value to it
    // if it is a property, access the property
    const key = typeof keyFinder === 'function' ? keyFinder(b) : b[keyFinder];

    // aggregate values based on the keys
    if (!a[key as keyof typeof a]) {
      a[key as keyof typeof a] = [b];
    } else {
      a[key as keyof typeof a] = [...a[key as keyof typeof a], b];
    }

    return a;
  }, {});
}

export async function* streamingFetch(fetchcall: () => Promise<Response>) {
  const response = await fetchcall();
  // Attach Reader
  const reader = response.body?.getReader();
  if (!reader) {
    throw new Error('Reader not found');
  }
  while (true) {
    // wait for next encoded chunk
    const { done, value } = await reader.read();
    // check if stream is done
    if (done) break;
    // Decodes data chunk and yields it
    yield new TextDecoder().decode(value);
  }
}

export function isUUID(data: unknown): data is string {
  return z.string().uuid().safeParse(data).success;
}

const MessageSchema = z.object({
  id: z.string().uuid().optional(),
  conversation_id: z.string().uuid(),
  role: z.enum(['system', 'user']),
  model: z.string(),
  content: z.string(),
  is_liked: z.boolean(),
  is_disliked: z.boolean(),
  is_last: z.boolean(),
  created_at: z.string(),
});
export type Message = z.infer<typeof MessageSchema>;
export function isMessage(data: unknown): data is Message {
  return MessageSchema.safeParse(data).success;
}
