<script setup lang="ts">
import { actions, isInputError } from 'astro:actions';
import { z } from 'astro:schema';
import { Button } from '@/components/ui/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Icon } from '@iconify/vue';
import { toTypedSchema } from '@vee-validate/zod';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'radix-vue';
import { useForm } from 'vee-validate';
import { ref } from 'vue';

const open = ref(false);
const isSuccess = ref(false);

const formSchema = toTypedSchema(
  z.object({
    name: z.string(),
    phone: z.string().optional(),
    email: z.string().email(),
    message: z.string(),
  }),
);

const { handleSubmit, setErrors, isSubmitting } = useForm({
  validationSchema: formSchema,
});

const onSubmit = handleSubmit(async (values) => {
  const { error } = await actions.contact.submitContact(values);
  if (isInputError(error)) {
    setErrors(error.fields);
    isSuccess.value = false;
  } else {
    isSuccess.value = true;
    open.value = false;
  }
});
</script>

<template>
  <DialogRoot v-model:open="open">
    <DialogTrigger>
      Contact
    </DialogTrigger>
    <DialogPortal>
      <DialogOverlay
        class="bg-zinc-950/80 backdrop-blur fixed inset-0 z-30 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
      <DialogContent
        class="fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[600px] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white px-5 py-8 z-[100] overflow-auto duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
        <form v-if="!isSuccess" class="grid gap-8 text-zinc-800" method="POST" @submit="onSubmit">
          <img class="mx-auto" src="/images/logo/relaxai-logo-dark.svg" width="186" height="36" alt="relax logo"
            loading="lazy" />

          <div class="grid gap-2">
            <DialogTitle class="text-2xl text-zinc-800 text-center font-semibold text-balance">
              Get in contact with us
            </DialogTitle>
            <DialogDescription class="text-zinc-500 text-center text-pretty">
              If you have any questions about relaxAI or have a technical issue, please use the form below and we’ll get
              back to you.
            </DialogDescription>
          </div>

          <div class="grid gap-6">
            <div class="contents md:grid md:grid-cols-2 gap-6">
              <FormField v-slot="{ componentField }" name="name">
                <FormItem>
                  <FormLabel>Full name</FormLabel>
                  <FormControl>
                    <Input type="text" autocomplete="name" v-bind="componentField" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              </FormField>

              <FormField v-slot="{ componentField }" name="phone">
                <FormItem>
                  <FormLabel>Phone number (optional)</FormLabel>
                  <FormControl>
                    <Input type="tel" inputmode="tel" v-bind="componentField" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              </FormField>
            </div>


            <FormField v-slot="{ componentField }" name="email">
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input type="email" inputmode="email" v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="message">
              <FormItem>
                <FormLabel>How can we help?</FormLabel>
                <FormControl>
                  <Textarea placeholder="Let us know how we can help you today" v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>

          <Button :disabled="isSubmitting">Submit</Button>
        </form>

        <div v-if="isSuccess" class="grid gap-8 text-zinc-800">
          <div class="grid gap-2">
            <DialogTitle class="text-2xl text-zinc-800 text-center font-semibold text-balance">
              Thank you for
            </DialogTitle>
            <DialogDescription class="text-zinc-500 text-center text-pretty">
              Your message has been sent and we’ll be in touch very soon
            </DialogDescription>
          </div>

          <DialogClose as-child>
            <Button type="button">Back to the homepage</Button>
          </DialogClose>
        </div>

        <DialogClose
          class="absolute top-[10px] right-[10px] inline-flex h-7 w-7 appearance-none text-zinc-800 items-center justify-center"
          aria-label="Close">
          <Icon icon="carbon:close" />
        </DialogClose>
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
</template>
